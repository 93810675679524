@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'primeflex/src/_variables.scss';
@import 'primeflex/src/_grid.scss';
@import 'primeflex/src/_formlayout.scss';
@import 'primeflex/src/_display.scss';
@import 'primeflex/src/_text.scss';
@import 'primeflex/src/flexbox/_flexbox.scss';
@import 'primeflex/src/_spacing.scss';
@import 'primeflex/src/_elevation.scss';

$breakpoints: (
  'sm': 'screen and (min-width: 576px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)'
) !default;

@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

body {
  background-color: rgb(245, 255, 255);
}

p, button, a, span {
  font-family: 'Noto Sans JP', 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

li {
  list-style-type: none
}

main {
  // margin-top: 240px;
  height: 100vh;
}

.home-col-info {
  max-width: 725px;
  // margin-left: auto;
}

.home-col-img {
  width: 350px;

  @include mq(sm) {
    width: 400px;
  }
  @include mq(md) {
    width: 500px;
  }
}

.MyName {
  font: normal normal bold 60px/80px Roboto;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  @include mq(sm) {
    font: normal normal bold 60px/80px Roboto;
  }
  @include mq(md) {
    font: normal normal bold 76px/100px Roboto;
  }
}

.MyJob {
  font: normal normal bold 28px/56px Roboto;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  @include mq(sm) {
    font: normal normal bold 38px/56px Roboto;
  }
  @include mq(md) {
    font: normal normal bold 48px/63px Roboto;
  }
}

.button-github {
  background-color: #24292e !important;
  border: 2px solid #24292e !important;
}

.button-twitter {
  background-color: #1da1f2 !important;
  border: 2px solid #1da1f2 !important;
}

.page-title {
  color: #404040;
  font: normal normal bold 28px Roboto;
}

.portfolio {
  max-width: 1500px;
  padding: 60px 16px 0 16px;
  margin: 0 auto;
}

.portfolio-list {
  margin-top: 32px;
}

.portfolio-card {
  max-width: 480px !important;
  margin: 0 auto;
}

.p-card-footer {
  text-align: center;
}

.add-content-center {
  text-align: center;
}